<template>
  <div class="wrapper">
    <div
      loading="lazy"
      class="banner"
      data-aos="fade-down"
      data-aos-delay="300"
    />
    <b-container
      id="formulario"
      class="mt-5 mb-5"
    >
      <b-row>
        <b-col
          class="contact-form agente"
          lg="6"
          data-aos="fade-in"
          data-aos-delay="400"
        >
          <form
            v-if="!sentSuccess"
            class="contact3-form validate-form"
            @submit.prevent="onSubmit"
          >
            <h2>{{ $t('message_form.Déjanos_tus_datos') }}</h2>
            <b-row>
              <b-col lg="6">
                <div class="form-group">
                  <label class="text-white">¿Cual es tu nombre?</label>
                  <b-input
                    v-model="form.name"
                    type="text"
                    placeholder="Tu nombre"
                    class="form-control"
                    required
                  />
                </div>
              </b-col>
              <b-col lg="6">
                <div class="form-group">
                  <label class="text-white">Tu mejor mail</label>
                  <b-input
                    v-model="form.email"
                    type="email"
                    placeholder="Tu mail"
                    class="form-control"
                    required
                  />
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="6">
                <div class="form-group">
                  <label class="text-white">¿A qué numero te llamamos?</label>
                  <b-input
                    v-model="form.number"
                    type="tel"
                    placeholder="Su teléfono"
                    class="form-control"
                    required
                  />
                </div>
              </b-col>
              <b-col lg="6">
                <div class="form-group">
                  <label class="text-white">¿De dónde eres?</label>
                  <b-input
                    v-model="form.donde"
                    type="text"
                    placeholder="Tu ciudad"
                    class="field"
                    required
                  />
                </div>
              </b-col>
            </b-row>
            <div class="form-group">
              <label class="text-white">{{ $t('convenio.Cual_es_tu_situación') }}</label>
              <div class="input-group">
                <label class="text-white mr-2"><input
                  v-model="form.situation"
                  type="radio"
                  name="radio0"
                  :value="$t('message_form.trabajador')"
                  required
                > {{ $t('message_form.trabajador') }} </label>
                <label class="text-white"><input
                  v-model="form.situation"
                  type="radio"
                  name="radio0"
                  :value="$t('message_form.practicar')"
                  required
                > {{ $t('message_form.practicar') }} </label>
              </div>
            </div>
            <div class="form-group">
              <ul class="list">
                <li>{{ $t('convenio.RGPD_youcan') }} <a href="/aviso-legal-privacidad/">{{ $t('convenio.RGPD_policy') }}</a></li>
                <li><strong>{{ $t('convenio.RGPD_responsible_catp') }}</strong>: {{ $t('convenio.RGPD_responsible_answ') }}</li>
                <li><strong>{{ $t('convenio.RGPD_finalidad') }}</strong>: {{ $t('convenio.RGPD_info') }}</li>
                <li><strong>{{ $t('convenio.RGPD_almacenamiento') }}</strong>: {{ $t('convenio.RGPD_almacenamiento_anws') }}</li>
                <li><strong>{{ $t('convenio.RGPD_right') }}</strong>: {{ $t('convenio.RGPD_right_anws') }} <a href="https://www.websincoste.com/page/aviso-legal-privacidad/">{{ $t('convenio.RGPD_limits') }}</a></li>
              </ul>
              <div class="text-white">
                <input
                  id="RGPD_OPTIN"
                  v-model="form.checked"
                  type="checkbox"
                  class="check"
                  required
                >
                {{ $t('convenio.He_leído') }}<a
                  href="/aviso-legal-privacidad/"
                  target="_blank"
                >{{ $t('convenio.políticas_de_privacidad') }}</a>
              </div>
            </div>
            <div class="form-group">
              <button
                id="send-btn"
                class="sib-form-block__button"
                type="submit"
              >
                {{ $t('common_contact_form.send') }}
              </button>
              <h3
                v-if="sentFailed"
                style="color: #ff9ca6;"
              >
                Error: ¡Inténtalo de nuevo!
              </h3>
            </div>
          </form>
          <div
            v-else-if="sentSuccess"
            class="send-success"
          >
            <h2>{{ $t('common_contact_form.thanks_message') }}</h2>
            <h3 style="color:#fff;">
              {{ $t('common_contact_form.will_contact') }}
            </h3>
            <div
              class="contact-info text-center mt-4"
              style="color:#fff"
            >
              <h4>Puedes contactarme por</h4>
              <div class="contact-nfo">
                <font-awesome-icon
                  icon="phone"
                  class="phone-icon"
                />
                <a
                  href="tel:+34928354668"
                  style="color:#fff"
                >+34 928 354 668</a>
              </div>
            </div>
          </div>
        </b-col>
        <b-col lg="1" />
        <b-col
          lg="5"
          class="right-text"
        >
          <h2
            data-aos="fade-down"
            data-aos-duration="500"
          >
            {{ $t('convenio.CONVENIO_DE_PRÁCTICAS_EN') }}
          </h2>
          <h1
            style=""
            data-aos="fade-down"
            data-aos-duration="300"
          >
            {{ $t('convenio.Empresas') }}
          </h1>
          <p>
            {{ $t('convenio.En_AICAD_las_prácticas') }}
          </p>
        </b-col>
      </b-row>
    </b-container>
    <b-container class="mt-5 mb-3">
      <h1 class="h1-bg">
        {{ $t('profesional_confienza.Dirigido') }}
      </h1>
      <b-row>
        <b-col
          lg="4"
          data-aos="fade-down"
          data-aos-delay="300"
        >
          <div class="text-container">
            <h1>
              {{ $t('profesional_confienza.AUTÓNOMOS') }}
            </h1>
            <h2>
              {{ $t('profesional_confienza.modo') }}
            </h2>
          </div>
        </b-col>
        <b-col
          lg="4"
          data-aos="fade-down"
          data-aos-delay="200"
        >
          <div class="text-container">
            <h1>
              {{ $t('profesional_confienza.TRABAJADORES') }}
            </h1>
            <h2>
              {{ $t('profesional_confienza.sector') }}
            </h2>
          </div>
        </b-col>
        <b-col
          lg="4"
          data-aos="fade-down"
          data-aos-delay="300"
        >
          <div class="text-container">
            <h1>
              {{ $t('profesional_confienza.PARTICULARES') }}
            </h1>
            <h2>
              {{ $t('profesional_confienza.empleo') }}
            </h2>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <div class="blue">
      <img
        loading="lazy"
        src="../assets/img/profesional_confienza/certificate.jpg"
        alt="profesional-de-confienza-certificate"
      >
    </div>
    <div class="modalidad">
      <h1 class="h1-bg">
        {{ $t('profesional_confienza.modalidad') }}
      </h1>
      <b-container>
        <b-row>
          <b-col
            lg="5"
            md="6"
            sm="12"
          >
            <p
              class="p-def"
              style="text-align: right"
            >
              {{ $t('profesional_confienza.Libertad') }}
            </p>
          </b-col>
          <b-col
            lg="1"
            md="6"
            sm="12"
          >
            <img
              loading="lazy"
              alt="profesional de confianza - 1"
              src="../assets/img/profesional_confienza/1.jpg"
              class="icons"
            >
          </b-col>
          <b-col
            lg="1"
            md="6"
            sm="12"
          >
            <img
              loading="lazy"
              alt="profesional de confianza - 2"
              src="../assets/img/profesional_confienza/2.jpg"
              class="icons"
            >
          </b-col>
          <b-col
            lg="5"
            md="6"
            sm="12"
          >
            <p
              class="p-def"
              style="text-align: left"
            >
              {{ $t('profesional_confienza.Accesos') }}
            </p>
          </b-col>
        </b-row>
        <br>
        <b-row>
          <b-col
            lg="5"
            md="6"
            sm="12"
          >
            <p
              class="p-def"
              style="text-align: right"
            >
              {{ $t('profesional_confienza.Certificado') }}
            </p>
          </b-col>
          <b-col
            lg="1"
            md="6"
            sm="12"
          >
            <img
              loading="lazy"
              alt="profesional de confianza - 3"
              src="../assets/img/profesional_confienza/3.jpg"
              class="icons"
            >
          </b-col>
          <b-col
            lg="1"
            md="6"
            sm="12"
          >
            <img
              loading="lazy"
              alt="profesional de confianza - 5"
              src="../assets/img/profesional_confienza/4.jpg"
              class="icons"
            >
          </b-col>
          <b-col
            lg="5"
            md="6"
            sm="12"
          >
            <p
              class="p-def"
              style="text-align: left"
            >
              {{ $t('profesional_confienza.Archivos') }}
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            lg="5"
            md="6"
            sm="12"
          >
            <p
              class="p-def"
              style="text-align: right"
            >
              {{ $t('profesional_confienza.Valioso') }}
            </p>
          </b-col>
          <b-col
            lg="1"
            md="6"
            sm="12"
          >
            <img
              loading="lazy"
              alt="profesional de confianza - 6"
              src="../assets/img/profesional_confienza/5.jpg"
              class="icons"
            >
          </b-col>
          <b-col
            lg="1"
            md="6"
            sm="12"
          >
            <img
              loading="lazy"
              alt="profesional de confianza - 7"
              src="../assets/img/profesional_confienza/6.jpg"
              class="icons"
            >
          </b-col>
          <b-col
            lg="5"
            md="6"
            sm="12"
          >
            <p
              class="p-def"
              style="text-align: left"
            >
              {{ $t('profesional_confienza.Contenidos') }}
            </p>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div 
      loading="lazy"
      class="people"
    >
      <b-container>
        <b-row style="text-align: right">
          <b-col
            lg="6"
            md="12"
            sm="12"
          >
            <h1>{{ $t('profesional_confienza.COMPETENCIAS') }}</h1>
            <h2>{{ $t('profesional_confienza.PARA') }}</h2>
          </b-col>
          <b-col
            lg="6"
            md="12"
            sm="12"
          >
            <img
              loading="lazy"
              class="logo"
              src="../assets/img/profesional_confienza/logo-designed.jpg"
              alt="profesional-de-confienza"
            >
          </b-col>
        </b-row>
        <b-row style="text-align: left">
          <b-col
            lg="6"
            md="12"
            sm="12"
          >
            <img
              loading="lazy"
              src="../assets/img/profesional_confienza/creation.jpg"
              alt="FORMACIÓN-ACORDE-ALDECRETO-LEY"
            >
          </b-col>
          <b-col
            lg="6"
            md="12"
            sm="12"
          >
            <h1>{{ $t('profesional_confienza.ACORDE') }}</h1>
          </b-col>
        </b-row>
      </b-container>
      <b-container class="big">
        <h1 class="h1-very-bg">
          {{ $t('profesional_confienza.CERTIFÍCATE') }}
        </h1>
        <p>{{ $t('profesional_confienza.clave') }}</p>
      </b-container>
    </div>
  </div>
</template>

<script>
export default {
    name: 'ProfesionalConfienza',
    data() {
        return {
            checkedNames: [],
            form: {
                name: '',
                email: '',
                number: '',
                situation: '',
                donde: '',
                checked: ''
            },
            status: 'not_accepted',
            sentSuccess: false,
            sentFailed: false
        };
    },
    methods: {
        onSubmit() {
            event.preventDefault();
            this.axios
                .post('https://www.websincoste.com/index.php', {
                    _nonce: 'f9d8d3d927baca5e53d95c32b41f6f00',
                    action: 'send_universal_form',
                    serialize: [{ name: 'Form Type', value: 'Agente Turistico Form' }, { name: 'Nombre', value: this.form.name }, { name: 'Email', value: this.form.email }, { name: 'Telefono', value: this.form.number }, { name: 'Situación', value: this.form.situation }, { name: 'Donde', value: this.form.donde }, { name: 'Reference Link', value: window.location.href }, { name: 'Aceptación Privacidad RGDP:', value: this.form.checked }]
                })
                .then(response => {
                    if (response.status === 200) {
                        this.sentSuccess = true;
                    }
                })
                .catch(error => {
                    this.sentFailed = true;
                    this.sentSuccess = false;
                    console.log(error);
                });
        }
    }
};
</script>

<style scoped lang="scss">
.wrapper {
	.agente.contact-form .send-success {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		text-align: center;
		height: 100%;
	}
	.h1-bg {
		font-family: LatoWebBlack, sans-serif;
		font-size: 5vh;
		text-align: center;
		color: #a7a7a7;
		@media only screen and (max-width: 424px) {
			font-size: 3rem;
		}
	}
	.h1-very-bg {
		font-family: LatoWebBlack, sans-serif !important;
		font-size: 5vh !important;
		text-align: center !important;
		color: whitesmoke !important;
		@media only screen and (max-width: 424px) {
			font-size: 3rem !important;
		}
	}
	.p-def {
		font-family: LatoWebBold, sans-serif;
		text-align: center;
		color: #555555;
	}
	.banner {
		background-image: url('../assets/img/profesional_confienza/Banner-profesional-de-Confianza_web.jpg');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		height: 500px;
		text-align: right;
		padding-right: 50px;
		padding-left: 50px;
		.text {
			max-width: 500px;
			width: 100%;
			float: right;
			position: relative;
			top: 200px;

			h1 {
				font-family: LatoWebBlackItalic, sans-serif;
				font-size: 2.5em;
				color: white;
				@media only screen and (max-width: 424px) {
					font-size: 1.7em;
					color: white;
				}
			}

			.hr {
				height: 2px;
				width: 100%;
				background-color: white;
				margin-bottom: 10px;
			}

			h2 {
				font-family: LatoWebMedium, sans-serif;
				font-size: 1.5rem;
				color: white;
			}

			.btn {
				border: none;

				background-color: white;
				font-family: LatoWebMedium, sans-serif;
				width: 100%;
				color: #008bf2;
			}
		}
	}
	.contact-form {
		margin-top: -130px;
		background-color: rgba(0, 0, 0, 0.8);
		text-align: left;
		padding: 20px;
		@media only screen and (max-width: 768px) {
			margin-bottom: 50px;
		}

		h2 {
			width: 100%;
			font-family: LatoWebBold, sans-serif;
			color: whitesmoke;
			font-size: 1.5rem;
		}

		.field {
			width: 100%;
			border-radius: 0px;
			margin-bottom: 10px;
		}

		.drop {
			width: 100%;
			border-radius: 0px;
			margin-bottom: 10px;
			background-color: white !important;
		}

		p {
			text-align: left;
			color: whitesmoke;
		}

		.check {
			text-align: left;
			color: white;
		}

		.list {
			text-align: left;
			color: whitesmoke;
			list-style: square;
		}

		@media only screen and (max-width: 424px) {
			margin-top: -50px;
		}
	}
	.right-text {
		h1 {
			color: #009090;
			font-family: LatoWebBlack, sans-serif;
			font-size: 3rem;
			letter-spacing: 2px;
			@media only screen and (max-width: 424px) {
				font-size: 3rem;
			}
		}

		h2 {
			font-family: LatoWebLight, sans-serif;
		}

		p {
			font-family: LatoWebSemiboldItalic, sans-serif;
			font-size: 1.3rem;
			color: #3d3d3d;
		}
	}
	.text-container {
		text-align: center;
		width: 100%;
		height: 100%;
		margin: 0 auto;
		padding: 20px;
		border-top: #2a89b9 10px solid;
		background-color: white;
		-webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
		-moz-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
		box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);

		h1 {
			font-family: LatoWebBlack, sans-serif;
			font-size: 1.5rem;
			color: #4b4b4b;
		}

		h2 {
			font-family: LatoWebLight, sans-serif;
			font-size: 1.2rem;
		}

		@media only screen and (max-width: 424px) {
			padding: 50px;
			margin-top: 0;
			width: 100%;
		}
		@media only screen and (max-width: 776px) {
			margin-top: 0;
			width: 100%;
		}
	}
	.blue {
		margin-top: -200px;
		padding: 300px 0 150px 0;
		background: #1e365c;
		img {
			display: block;
			margin: 0 auto;
			width: 100%;
			max-width: 1000px;
			min-width: 200px;
			@media only screen and (max-width: 500px) {
				position: relative;
			}
		}
	}
	.modalidad {
		margin-top: 100px;
		margin-bottom: 100px;
	}
	.people {
		background: linear-gradient(236deg, rgba(77, 77, 77, 0.7) 0%, rgba(77, 77, 77, 0.7) 100%), url('../assets/img/profesional_confienza/business-team-working-research-planning-concept.jpg'), no-repeat;
		background-size: cover;
		background-position: bottom center;
		height: auto;
		.row {
			margin-top: 100px;
			padding: 50px 0;
		}
		img {
			-webkit-box-shadow: 4px 10px 17px 0px rgba(78, 80, 102, 1);
			-moz-box-shadow: 4px 10px 17px 0px rgba(78, 80, 102, 1);
			box-shadow: 4px 10px 17px 0px rgba(78, 80, 102, 1);

			width: 100%;
			margin: 20px 0;
			max-width: 500px;
			min-width: 200px;
		}

		h1 {
			font-family: LatoWebBold, sans-serif;
			font-size: 6vh;
			color: white;
			@media only screen and (max-width: 424px) {
				font-size: 2rem;
			}
		}

		h2 {
			font-family: LatoWebMedium, sans-serif;
			font-size: 4vh;
			color: white;
			@media only screen and (max-width: 424px) {
				font-size: 1.8rem;
			}
		}

		p {
			margin: 0 auto;
			text-align: center;
			font-family: LatoWebMedium, sans-serif;
			color: white;
			max-width: 450px;
			@media only screen and (max-width: 424px) {
				font-size: 1.5rem;
			}
		}
		.big {
			padding: 0px 0px;
		}
	}
	.tenth {
		margin-top: -110px;
		background-image: url('../assets/img/practica/green-flesh.png');
		background-repeat: no-repeat;
		background-position: center;
		height: 500px;
		background-size: cover;
		text-align: center;

		h1 {
			padding-top: 280px;
			align-content: center;
			font-family: LatoWebBlack, sans-serif;
			font-size: 5vh;
			letter-spacing: 7px;
			color: white;
			@media only screen and (max-width: 430px) {
				font-size: 5vh;
				margin-top: -20px;
			}
		}
	}
	.feedback {
		position: relative;
		z-index: 99;
		margin: -100px 0 0 0;
		text-align: center;
		img {
			width: 100%;
			max-width: 600px;
			min-width: 300px;
		}
	}
	.icons {
		width: 50px;
	}
}
</style>
